<div class="container">
    <h1>Users</h1>
    <div *ngIf="!loading; else isLoading">
        <div class="action-buttons">
            <button mat-flat-button color="primary" [routerLink]="['/admin/user/0']">New User</button>
        </div>
        <table class="admin-table">
            <thead>
            <th (click)="sort('name')" style="width: 30%">Name</th>
            <th (click)="sort('email')" style="width: 50%">Email</th>
            <th (click)="sort('role')"style="width: 10%">Role</th>
            <th (click)="sort('status')"style="width: 10%">Status</th>
            <th (click)="sort('last_login')"style="width: 10%">Last Login</th>
            <th></th>
            </thead>
            <tbody>
            <tr *ngFor="let user of users" (click)="view(user)">
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.role}}</td>
                <td>{{user.status}}</td>
                <td>{{user.last_login}}</td>
            </tr>
            </tbody>
        </table>

    </div>

    <ng-template #isLoading>
        Loading...
    </ng-template>
</div>
