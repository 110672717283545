import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import {ApiService} from '../services/api.service'
import { Utils } from '../services/utils'

@Component({
  templateUrl: './report_form.html'
})

export class ReportFormComponent implements OnInit {

  report:any = {country: 'United States', product: {manufacturer: {}}, opts: []}
  loadCounter = 0
  loading = true
  isNew = true
  sections = []
  products = []
  manufacturers = []
  showProductForm=false
  showMfrForm=false
  countries = ['United States']
  saving = false
  textOpts = {}

  constructor(private route: ActivatedRoute,
              private router: Router,
              private api: ApiService) {

  }

  bumpLoaded(): void {
    this.loadCounter++
    if (this.loadCounter >= 3) {
      this.loading = false
    }
  }

  setUpSelections(): void {
    Utils.jsonTraverse(this.sections, (key, value, scope) => {
      if (key === 'kind' && (value === 'select' || value === 'multiple') ) {
        scope.options.forEach(opt => {
          if (opt.id in this.report.options) {
            scope.selected_option = opt.id
            scope.is_verified = this.report.options[opt.id]
          }
        })
      }
      if (key === 'kind' && value === 'slider') {
        this.textOpts[scope.id] = ''
        scope.options.forEach(opt => {
          if (opt.id in this.report.options) {
            this.textOpts[scope.id] = opt.value
            scope.is_verified = this.report.options[opt.id]
          }
        })
      }
    })
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      // edit report
      this.api.get(`report_sources/${id}`).subscribe(res => {
        this.report = res
        this.sections = res.sections
        this.setUpSelections()
        this.isNew = false
        this.bumpLoaded()
      })
    } else {
      this.api.get(`sections`).subscribe(res => {
        this.sections = res
        this.report = {country: 'United States', product: {manufacturer: {}}, opts: []}
        this.bumpLoaded()
      })
    }

    this.api.get('manufacturers').subscribe(res => {
      this.manufacturers = res
      this.bumpLoaded()
    })
    this.api.get('products').subscribe(res => {
      this.products = res
      this.bumpLoaded()
    })

  }

  setProdForm(val: boolean): void {
    this.showProductForm = val
    if (this.showProductForm) {
      this.report.product_id = null;
    } else {
      this.report.product = {manufacturer: {}}
    }
  }

  setMfrForm(val: boolean): void {
    this.showMfrForm = val
    if (this.showMfrForm) {
      this.report.product.manufacturer_id = null
    } else {
      this.report.product.manufacturer = {}
    }
  }

  // really only need to set selected_option here.
  // leaving this code for now
  toggleOptEvt(event, property) {
   // const pOptIds = property.options.map(o => o.id)
    // remove other opts from list
  //  this.report.options = this.report.options.filter(oId => !pOptIds.includes(oId))

    const id = event.source.value
    property.selected_option = id
    if ( typeof property.is_verified === 'undefined') {
      property.is_verified = this.report.is_verified
    }
  //  this.toggleOpt(id)
  }

  toggleOpt(id) {
    const idx = this.report.options.indexOf(id)
    if (idx > -1) {
      this.report.options.splice(idx, 1)
    } else {
      this.report.options.push(id)
    }
  }

  toggleVerifieds(): void {
    Utils.jsonTraverse(this.sections, (key, value, scope) => {
      if (key === 'kind') {
        if (typeof scope.is_verified !== 'undefined') {
          scope.is_verified = this.report.is_verified
        }
      }
    })
  }

  saveProperties(): void {
    this.saving = true
    const updatedR =  JSON.parse(JSON.stringify(this.report))
    delete updatedR.sections
    updatedR.property_options = {} // updatedR.options
    updatedR.text_opts = {}  // = this.textOpts

    Utils.jsonTraverse(this.report.sections, (key, value, scope) => {
      if (key === 'kind' && (value === 'select' || value === 'multiple') ) {
        if (scope.selected_option) {
          updatedR.property_options[scope.selected_option] = scope.is_verified || false
        }
      }
      if (key === 'kind' && value === 'slider') {
        this.textOpts[scope.id] = ''
        scope.options.forEach(opt => {
          if (opt.id in updatedR.options) {
            updatedR.text_opts[scope.id] = {is_verified: scope.is_verified, val: opt.value}
          }
        })
      }
    })
    delete updatedR.opts
    delete updatedR.options

    if (this.isNew) {
      this.api.post('report_sources', updatedR).subscribe(res => {

        this.router.navigate([`/admin/report/${res.id}`])
        this.saving = false
      }, error => {
        if(error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          console.log(error)
          this.saving = false
        }
      })

    } else {
      delete updatedR.property_id // cannot change this
      this.api.put('report_sources/'+this.report.id, updatedR).subscribe(res => {
        this.saving = false
        this.router.navigate([`/admin/report/${this.report.id}`])
      }, error => {
        if(error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          console.log(error)
          this.saving = false
        }
      })
    }

  }

}
