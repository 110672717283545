<div class="login-background-sealed">
    <div class="login-container">
        <div class="content" *ngIf="!localLogin">
            <p>
                **WARNING**WARNING** WARNING**WARNING** <br /> <br />
                This is a Department of Energy (DOE) computer system. DOE computer systems are provided for the processing of
                official U.S. government information only. All data contained within DOE computer systems is owned by the DOE,
                and may be audited, intercepted, recorded, read, copied, or captured in any manner and disclosed in any manner,
                by authorized personnel. THERE IS NO RIGHT OF PRIVACY IN THIS SYSTEM. System personnel may disclose any
                potential evidence of crime found on DOE computer systems to appropriate authorities. USE OF THIS SYSTEM BY ANY
                USER, AUTHORIZED OR UNAUTHORIZED, CONSTITUTES CONSENT TO THIS AUDITING, INTERCEPTION, RECORDING, READING,
                COPYING, CAPTURING, and DISCLOSURE OF COMPUTER ACTIVITY. <br /> <br />
                **WARNING**WARNING** WARNING**WARNING**.
                <span *ngIf="testSite()"><br> <br> ** TRAINING SITE ONLY **<br /> <br />Do Not Enter Actual DOE Site Survey Data<br /> <br />** TRAINING SITE ONLY **</span>
            </p>

            <p *ngIf="declined()">
                Session Ended Please Close Your Browser
            </p>

            <p *ngIf="redirecting()">
                Redirecting to complete authorization in
                {{ redirectCountdown }}
            </p>
        </div>

        <div class="actions" *ngIf="deciding()">
            <button class="subtle" (click)="declineWarning()">
                Decline
            </button>
            <button (click)="acceptWarning()">
                Accept
            </button>
        </div>

        <div class="content" *ngIf="localLogin">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">

                <mat-form-field appearance="fill" class="input-full-w">
                    <mat-label>Username</mat-label>
                    <input type="email" matInput id="newAnswerName" formControlName="username">
                    <mat-error *ngIf="submitted && f.username.errors?.required">
                        Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="input-full-w">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput id="password" formControlName="password">
                    <mat-error *ngIf="submitted && f.password.errors?.required">
                        Password is <strong>required</strong>
                    </mat-error>
                </mat-form-field>

                <button mat-flat-button color="primary">
                    Login</button>
            </form>
        </div>

    </div>
</div>