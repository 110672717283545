<div class="container admin-report">
    <button mat-button color="primary" [routerLink]="['/admin/users']">< Back</button>

    <div *ngif="loading; else loaded">
        Loading ...
    </div>
    <div #loaded>

        <h1 *ngIf="selectedUser.id">{{selectedUser.name}}</h1>
        <h1 *ngIf="!selectedUser.id">New User</h1>

        <mat-form-field class="admin-prod-detail-field">
            <mat-label>Name</mat-label>
            <input matInput type="text" required [(ngModel)]="selectedUser.name"/>
        </mat-form-field>
        <mat-form-field class="admin-prod-detail-field">
            <mat-label>Email</mat-label>
            <input matInput type="text" required [(ngModel)]="selectedUser.email"/>
        </mat-form-field>
        <mat-form-field class="admin-prod-detail-field">
            <mat-label>Role</mat-label>
            <mat-select class="admin-input" required [(ngModel)]="selectedUser.role">
                <mat-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="admin-prod-detail-field">
            <mat-label>Status</mat-label>
            <mat-select class="admin-input" required [(ngModel)]="selectedUser.status">
                <mat-option *ngFor="let status of statuses" [value]="status">{{ status }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="action-buttons">
            <button mat-flat-button color="secondary" [routerLink]="['/admin/users']" [disabled]="saving">Cancel</button>
            <button mat-flat-button color="primary" (click)="saveUser()" [disabled]="saving">Save Changes</button>
        </div>
    </div>

</div>
