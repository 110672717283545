import { Component, OnInit } from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {ApiService} from '../services/api.service'
import {formatCurrency} from "@angular/common";

@Component({
  templateUrl: './report.html'
})

export class ReportComponent implements OnInit {

  selectedReport:any = null
  loading = true

  constructor(private router: Router,
              private route: ActivatedRoute,
              private api: ApiService) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.api.get(`report_sources/${id}`).subscribe(res => {
        this.selectedReport = res

      })
    } else {
      this.router.navigate(['/admin/reports'])
    }
  }

  displayCurrency(value: number) {
    return formatCurrency(value, 'en-US', '$').replace('.00', '')
  }

  getValue(property: any) {
    let v = null
    property.options.forEach(o => {
      if (o.id in this.selectedReport.options) {
        v = o.value
      }
    })
    return v
  }

  getIsVerified(property: any) {
    let v = false
    property.options.forEach(o => {
      if (this.selectedReport.options[o.id]) {
        v = this.selectedReport.options[o.id]
      }
    })
    return v
  }


}
