import { HostListener, Component } from '@angular/core';
import { environment } from '../environments/environment';

@Component({ selector: 'app-root', templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'cuas'
  time = null

  @HostListener('click', ['$event'])
  onClick(event: KeyboardEvent) {
    this.resetTimer()
  }

  constructor() {
    this.resetTimer()
  }

  handleTimeout() {
    localStorage.removeItem('user');
    if(environment.production) {
      window.location.href = `${environment.baseUrl}saml/login`
    } else {
      window.location.href = `${environment.appUrl}`
    }
  }

  resetTimer() {
    clearTimeout(this.time);
    this.time = setTimeout(this.handleTimeout, 900000)
  }

}
