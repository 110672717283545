import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// App
import { AppComponent } from './app.component'
import { SearchComponent } from './search/component'
import { HomeComponent } from './home/home'
import { LoginComponent } from './account/login'
import { AdminComponent, ReportComponent, ReportsComponent, ReportFormComponent, UsersComponent, UserComponent } from './admin/index'
// import { NavComponent } from './nav/nav'
import { JwtInterceptor } from './services/jwt_interceptor'

// Header component
import { AppHeaderComponent } from './header/app-header'

// Material
import { MatSelectModule } from '@angular/material/select'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSliderModule } from '@angular/material/slider'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatRippleModule } from '@angular/material/core'
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatSidenavModule} from '@angular/material/sidenav'
import {MatListModule} from '@angular/material/list'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatRadioModule } from '@angular/material/radio';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';

@NgModule({
  declarations: [
        AppComponent, AppHeaderComponent, LoginComponent, HomeComponent, SearchComponent, AdminComponent,
        ReportComponent, ReportsComponent, ReportFormComponent, UsersComponent, UserComponent, LoadingIndicatorComponent ],
    imports: [
        // Basics
        CommonModule, BrowserModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule,
        // Material
        MatSelectModule, MatButtonModule, MatIconModule, MatSliderModule, MatRippleModule, MatTooltipModule, MatInputModule,
        MatToolbarModule, MatSidenavModule, MatListModule, FormsModule, ReactiveFormsModule, MatMenuModule,
        MatCheckboxModule, MatRadioModule
    ],
  exports: [ ],
  providers: [ { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true } ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
