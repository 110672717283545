import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({ templateUrl: './admin.html' })

export class AdminComponent {
  currentPage = 'Reports'

  constructor(private router: Router){

  }

  setPage(page: string, pagePath: string): void {
    this.currentPage = page
    this.router.navigate([pagePath])
  }
}
