import {Component, Input, OnInit} from '@angular/core'
import { Router }from '@angular/router'
import { ApiService } from '../services/api.service'
import { formatCurrency } from '@angular/common'
import { AccountService } from '../services/account.service'
import { environment } from '../../environments/environment'

@Component({ templateUrl: "./component.html" })
export class SearchComponent implements OnInit {
  // Pages
  data: any;
  sectionIdx = 0;
  viewResults = false;
  matchCount = 0;
  showSummary = false;
  showAll = false;
  tolerance = 90;
  dataLoaded = false;
  user = null;
  dirtySections = [];
  message: string;

  constructor(
    private router: Router,
    private api: ApiService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.user = this.accountService.userValue;
    const errFn = (error) => {
      if (error.status === 401) {
        this.router.navigate(["/login"]);
      } else {
        console.log(error);
      }
    };
    this.api.get("tools").subscribe((res) => {
      this.data = res;
      this.matchCount = this.data.matchCount; // this.data.properties.filter(p => p.kind !== 'data').length
      this.searchFilters();
      this.accountService.setLastUpdate(res.lastUpdated);
      for (let i = 0; i < this.data.sections.length; i++) {
        this.dirtySections[i] = [];
      }
    }, errFn);
    if (!this.user) {
      this.router.navigate(["/login"]);
    }
  }

  resetSection() {
    if (confirm("Are you sure you wish to reset all selections?")) {
      location.reload();
    }
  }
  /**
  isSectionDirty(idx) {
    if(this.dirtySections[idx].length > 0) {
      return true
    } else {
      return false
    }
  }
  isDirty(property, idx = null) {
    let retVal = null
    if (property.settings.multiple === true) {
      // tslint:disable-next-line:max-line-length
      if (property.settings.value.length === property.settings.options.length && property.settings.value.every(el => property.settings.options.includes(el))) {
        if (idx != null) {
          if (this.dirtySections[idx].includes(property.id)) {
            // console.log('removing id: ' + property.id)
            this.dirtySections[idx].splice(this.dirtySections[idx].indexOf(property.id), 1)
          }
        }
        retVal = false
      } else {
        if (idx != null) {
          if (!this.dirtySections[idx].includes(property.id)) {
            // console.log('pushing id: ' + property.id)
            this.dirtySections[idx].push(property.id)
          }
        }
        retVal = true
      }
    } else {
      if (property.settings.value !== property.settings.options[0]) {
        if (idx != null) {
          if (!this.dirtySections[idx].includes(property.id)) {
            // console.log('pushing id: ' + property.id)
            this.dirtySections[idx].push(property.id)
          }
        }
        retVal = true
      } else {
        if (idx != null) {
          if (this.dirtySections[idx].includes(property.id)) {
            // console.log('removing id: ' + property.id)
            this.dirtySections[idx].splice(this.dirtySections[idx].indexOf(property.id), 1)
          }
        }
        retVal = false
      }
    }
    // console.log(`retval: ${retVal} for id: ${property.id}`)
    // console.log(this.dirtySections)
    return retVal
  }
   **/
  // Search Filters
  searchFilters(event = null, property = null) {
    // Two-way binding is unpredictable
    if (event && property) {
      property.settings.value = event.source.value;
      property.dirty =
        JSON.stringify(property.default) ===
        JSON.stringify(property.settings.value)
          ? false
          : true;
      if (property.dirty) {
        property.active = true;
      } else {
        property.active = false;
      }
    }
    // get all the properties that have data
    const searchProps = this.data.properties.filter(
      (pp) => pp.searchable && pp.kind !== "data"
    );

    // doubling up on this with a new search that only filters based on selected criteria
    const filters = searchProps.filter((pp) => pp.dirty);
    // Search Each Product
    this.data.products.forEach((product) => {
      // if product has no properties, then no match
      if (product.properties && product.properties.length > 0) {
        // doubling up the search for two types of results
        // Filter Each property
        product.matchCount = this.matchCount;
        product.is_result = filters.length === 0;
        let numMatches = 0;
        filters.forEach((prop) => {
          let productProp = product.properties.find(
            (pp) => pp.property_id === prop.id
          );

          if (productProp) {
            productProp = this._checkProdMatch(product, prop);

            if (productProp.match) {
              numMatches++;
            }
          } else if (prop.kind === "slider") {
            // productProp = this._computeRangeMatch(productProp, prop);
            // if there is no product prop match, but we want to use computeRangeMatch to consider missing props as true for certain attributes
            numMatches++;
          }
        });

        if (numMatches === filters.length) {
          product.is_result = true;
        }
        // reset -- for percentage
        // Filter Each property
        product.matchCount = this.matchCount;
        // check all props that have data and see if they have matches on this product
        searchProps.forEach((prop) => {
          this._checkProdMatch(product, prop);
        });
        product.match = ((100 * product.matchCount) / this.matchCount).toFixed(
          0
        );
      } else {
        product.match = 0;
        product.is_result = false;
      }
      this.dataLoaded = true;
    });
  }

  _checkProdMatch(product, prop) {
    let productProp = product.properties.find(
      (pp) => pp.property_id === prop.id
    );

    if (!productProp) {
      if (prop.dirty) {
        product.matchCount--;
      } else {
        // do nothing ? not sure
      }
    } else {
      if (prop.settings.value === "Any") {
        productProp.match = true;
      } else {
        if (prop.kind === "slider") {
          productProp.match = productProp.value[0] <= prop.settings.value;
        } else if (prop.kind === "select") {
          if (prop.settings.multiple) {
            // Multiple: Includes any of the selected options

            productProp.match = prop.settings.value.some((o) =>
              productProp.value.includes(o)
            );
          } else if (prop.settings.priority) {
            if (prop.settings.value.includes("More")) {
              // if the option has more in it, you can't include the less than values
              productProp.match = prop.settings.value === productProp.value;
            } else {
              // Priority: Any of the options based on the selected and under
              const options = prop.settings.options.slice(
                prop.settings.options.indexOf(prop.settings.value),
                prop.settings.options.length
              );
              productProp.match = options.some((o) =>
                productProp.value.includes(o)
              );
            }
          } else {
            // Default: Simple equals
            if (Array.isArray(productProp.value)) {
              productProp.match = productProp.value.includes(
                prop.settings.value
              );
            } else {
              productProp.match = prop.settings.value === productProp.value;
            }
          }
        }

        // after normal checks, do a last chance to handle special range cases
        if (this._getRangeOptions(prop)) {
          productProp.match = this._computeRangeMatch(productProp, prop);
        }
      }

      if (!productProp.match) {
        product.matchCount--;
      }

      return productProp;
    }
  }
  _getRangeOptions(prop: any) {
    const rangeOptions = {
      "Maximum Range": [
        "Up to 100m",
        "100m - 1km",
        "1km - 5km",
        "5km - 10km",
        "Greater than 10km",
      ],
      "Spatial Coverage": ["90° Quadrant", "180° Semi-circle", "360°"],
    };

    return rangeOptions[prop.name];
  }
  _computeRangeMatch(productProp: any, prop: any) {
    const options = this._getRangeOptions(prop);

    if (!options) {
      return false;
    }

    if (!productProp) {
      // HERE, if we want to include products that do not have the prop set as a match in a range, return true, otherwise return false
      return true;
    }

    const ppValue = productProp.value[0];

    if (!ppValue) {
      return false;
    }

    const selectedIndex = options.indexOf(prop.settings.value);
    const ppValueIndex = options.indexOf(ppValue);

    return ppValueIndex <= selectedIndex;
  }
  goToAdmin() {
    const adminUrl =
      environment.apiBaseUrl + "auth/admin_login?api_token=" + this.user.token;
    window.location.href = adminUrl;
  }

  // Main Sections Controls
  setSection(idx: number) {
    this.viewResults = false;
    this.sectionIdx = idx < 0 || idx > this.data.sections?.length - 1 ? 0 : idx;
  }
  setViewResults() {
    this.viewResults = true;
    this.sectionIdx = -1;
  }

  // Helpers
  displayCurrency(value: number) {
    return formatCurrency(value, "en-US", "$").replace(".00", "");
  }
  getSelectedFilters() {
    return this.data?.properties;
  }
  getProperties(section: any) {
    const blacklist = [
      { sectionName: "Detection Methods & Capability", name: "FAR Rate" },
      { sectionName: "Detection Methods & Capability", name: "NAR Rate" },
      { sectionName: "Tracking Capability", name: "Accuracy" },
      { sectionName: "Tracking Capability", name: "Library Only" },
      {
        sectionName: "Infrastructure Requirements",
        name: "Multiple Systems Deployed",
      },
      {
        sectionName: "Environmental Constraints",
        name: "Severe",
      },
    ];

    return (
      this.data?.properties
        .filter((p) => p.section_id === section.id && p.kind !== "data")
        .filter((p) => {
          return !!!blacklist.find(
            (bl) => bl.sectionName === section?.name && bl.name === p.name
          );
        }) || []
    );
  }
  getAllProperties(section: any, product: any) {
    let props =
      this.data?.properties.filter((p) => p.section_id === section.id) || [];
    let pps = product.properties.map((pp) => pp.property_id);

    return props.filter((p) => pps.includes(p.id));
  }
  getProductReports(productId) {
    return this.data?.reports.filter((x) => x.product_id === productId);
  }

  getPricePropertyId() {
    return this.data.properties.find(
      (p) =>
        p.name ===
        "Select your total C-UAS procurement budget. If cost is not a requirement, leave the default option to achieve the maximum number of results."
    ).id;
  }

  getCountryPropertyId() {
    return this.data.properties.find((p) => p.name === "Country of origin").id;
  }

  getDetectionPropertyIds() {
    return this.data.properties
      .filter((obj) =>
        ["Detection1", "Detection2", "Detection3"].includes(obj.name)
      )
      .map((obj) => obj.id);
  }

  sectionHasValues(section: any, product: any) {
    let ps = this.data?.properties
      .filter((p) => p.section_id === section.id)
      .map((p) => p.id);
    let props = product.properties.filter((pp) => ps.includes(pp.property_id));

    return props.length > 0;
  }

  sectionReportHasValues(section: any, product: any, report: any) {
    const ps = this.data?.properties
      .filter((p) => p.section_id === section.id)
      .map((p) => p.id);
    const props = report.properties.filter((pp) => ps.includes(pp.property_id));
    return props.length > 0;
  }

  getSectionReportProperties(section: any, product: any, report: any) {
    const ps = this.data?.properties
      .filter((p) => p.section_id === section.id)
      .map((p) => p.id);
    const reportProps = report.properties.filter((pp) =>
      ps.includes(pp.property_id)
    );
    const props = report.properties
      .filter((pp) => ps.includes(pp.property_id))
      .map((p) => p.property_id);
    const displayProps = this.data?.properties.filter((p) => {
      if (props.includes(p.id)) {
        const rp = reportProps.find((r) => r.property_id === p.id);
        p.is_verified = rp.value[0].is_verified;
        return p;
      }
    });

    return displayProps;
  }

  getProductMatch() {
    return this.data?.products
      .filter((p) => p.match >= this.tolerance)
      .sort((a, b) => a.match.localeCompare(b.match));
  }
  getNumberResults() {
    return this.data?.products.filter((p) => p.is_result === true);
  }
  getResults() {
    if (this.showAll) {
      return this.data?.products;
    } else {
      return this.data?.products.filter((p) => p.is_result === true);
    }
  }
  property(product, propertyId, joinstr = "") {
    const match =
      product.properties.find((p) => p.property_id === propertyId)?.value || "";
    if (Array.isArray(match) && match.length > 1) {
      return match
        .filter(function (value, index) {
          return match.indexOf(value) === index;
        })
        .join(joinstr);
    } else {
      return match;
    }
  }
  dataProperty(product, propertyIds, joinstr = "") {
    const match =
      product.properties.find((p) => propertyIds.includes(p.property_id))
        ?.value || [];
    if (Array.isArray(match) && match.length > 1) {
      return match
        .filter(function (value, index) {
          return match.indexOf(value) === index;
        })
        .join(joinstr);
    } else {
      return match;
    }
  }
  propertyAsNumber(product, propertyId) {
    const val = this.property(product, propertyId);
    if (Array.isArray(val)) {
      return val[0];
    } else {
      return val;
    }
  }

  isPropertyMatch(product, propertyId) {
    return product.properties.find((p) => p.property_id === propertyId)?.match;
  }
  propertyMatch(product, propertyId) {
    return product.properties.find((p) => p.property_id === propertyId)?.match
      ? "check"
      : "clear";
  }
  propertyReportSource(productId, propertyId) {
    let title = `Report: `;

    const reportIds = this.data?.reports.filter(
      (x) => x.product_id === productId
    );

    if (Array.isArray(reportIds)) {
      reportIds.forEach((r, index) => {
        title += r.display_name;

        if (index !== reportIds.length - 1) {
          title += `\nReport: `;
        }
      });
    } else {
      title = reportIds.display_name;
    }

    return `${title}`;
  }

  getReportPropertyDisplay(reportProperty) {
    if (reportProperty.name.includes("procurement budget")) {
      return "Cost";
    } else {
      return reportProperty.name;
    }
  }
}
