import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-loading-indicator",
  template: ` <p>Fetching products<span class="dots"></span></p> `,
  styles: [
    `
      @keyframes dots {
        0% {
          content: ".";
        }
        33% {
          content: "..";
        }
        66% {
          content: "...";
        }
        100% {
          content: ".";
        }
      }

      .dots::after {
        content: "";
        animation: dots 1s steps(3, end) infinite;
      }
    `,
  ],
})
export class LoadingIndicatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
