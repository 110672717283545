<div class="container admin-report">
    <button mat-button color="primary" [routerLink]="['/admin/reports']">< Back</button>
    <h1>Report: {{selectedReport.display_name}} <mat-icon *ngIf="selectedReport.is_verified" class="verified-badge" aria-label="Verified">verified</mat-icon></h1>
    <div class="action-buttons">
        <button mat-flat-button color="primary" [routerLink]="['/admin/report_form', selectedReport.id]">Edit</button>
    </div>
    <table class="details-panel">
        <h2 class="section-title">Report Details</h2>
        <tr><td class="details-label">Product: </td><td>{{selectedReport.product.name}}</td></tr>
        <tr><td class="details-label">Model: </td><td>{{selectedReport.product.model}}</td></tr>
        <tr><td class="details-label">Manufacturer: </td><td>{{selectedReport.product.manufacturer.name}}</td></tr>
        <tr><td class="details-label">Website: </td><td>{{selectedReport.product.manufacturer.website}}</td></tr>
        <tr><td class="details-label">Test Event: </td><td>{{selectedReport.test_event}}</td></tr>
        <tr><td class="details-label">Location: </td><td>{{selectedReport.test_report_location}}</td></tr>
        <tr><td class="details-label">Country: </td><td>{{selectedReport.country}}</td></tr>
        <tr><td class="details-label">Date: </td><td>{{selectedReport.test_report_date}}</td></tr>
        <tr><td class="details-label">Title: </td><td>{{selectedReport.test_report_title}}</td></tr>
        <tr><td class="details-label">Organization: </td><td>{{selectedReport.test_org}}</td></tr>
    </table>

    <div class="property-list">
        <h2 class="section-title">Property Details</h2>
        <ng-container *ngFor="let section of selectedReport.sections">
            <h3 class="section-title">{{ section.name }}</h3>
            <div *ngFor="let prop of section.properties" >
                <label>{{ prop.name }}</label>
                <span class="property-value">{{getValue(prop)}}</span>
                <span class="material-icons-outlined verified-badge small" *ngIf="getIsVerified(prop)">verified</span>
            </div>
        </ng-container>
    </div>

</div>
