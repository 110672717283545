import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home';
import { SearchComponent } from './search/component';
import { AdminComponent, ReportComponent, ReportsComponent, ReportFormComponent, UsersComponent, UserComponent } from './admin';
import { AuthGuard } from './services/auth.guard';
import { LoginComponent } from './account/login';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard],
    children: [{ path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
               { path: 'report/:id', component: ReportComponent, canActivate: [AuthGuard] },
               { path: 'report_form', component: ReportFormComponent, canActivate: [AuthGuard] },
               { path: 'report_form/:id', component: ReportFormComponent, canActivate: [AuthGuard] },
               { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
               { path: 'user/:id', component: UserComponent, canActivate: [AuthGuard] }]},

  { path: 'login', component: LoginComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
