import {Component, Input, OnInit} from '@angular/core'
import {environment} from '../../environments/environment'
import { AccountService } from '../services/account.service'
import {Router} from "@angular/router";
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.html',
})

export class AppHeaderComponent implements OnInit {
    showAdminButton: boolean
    showSuperAdminButton: boolean
    userToken: string
    updateSub: Subscription;
    userSub: Subscription;
    lastUpdate:string = null;
    user$: Observable<any>

    constructor(public accountService: AccountService, private router: Router) {

    }

    ngOnInit() {
        this.user$ = this.accountService.user

        this.userSub = this.accountService.user.subscribe(res => {
            if (res) {
                this.userToken = res.token
            }
        })

        this.updateSub = this.accountService.lastUpdate.subscribe(res => {
            this.lastUpdate = res;
        })
    }

    ngOnDestroy(): void {
        this.updateSub.unsubscribe()
        this.userSub.unsubscribe()
    }

    goToAdmin() {
        if (this.userToken) {
            this.router.navigate(['/admin/reports'])
        }
    }

    goToSuperAdmin() {
        if (this.userToken) {
            const adminUrl = environment.apiBaseUrl+'auth/admin_login?api_token='+this.userToken
            window.open(adminUrl, "_blank")
        }
    }
    logout(): void {
        this.accountService.logout()
    }

}
