import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AccountService } from '../services/account.service'
import { first } from 'rxjs/operators'
import { environment } from '../../environments/environment'

@Component({ templateUrl: './login.html' })

export class LoginComponent {
    loginUrl = `${environment.baseUrl}saml/login`
    redirectCountdown = 3
    userResponse = 'pending'
    localLogin = false
    form: FormGroup
    submitted = false

    // tslint:disable-next-line:max-line-length
    constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private accountService: AccountService) {
        let token = null;
        this.route.queryParamMap.subscribe(res => {
            token = res.get("apiToken")

            if(token) {
                this.accountService.loginWithToken(token).pipe(first())
                    .subscribe({
                        next: () => {
                            this.router.navigate(['/']);
                        },
                        error: error => {
                            console.log("there was an error loggign in ")
                            if(environment.production) {
                                this.goToSamlLogin();
                            } else {
                                this.localLogin = true;
                            }
                        }
                    });
            } // else {
                // this.goToSamlLogin();
            // }
        })

    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    declineWarning() {
        // what to do here
        this.userResponse = 'declined';
    }

    declined(): boolean {
        return this.userResponse == 'declined';
    }

    acceptWarning() {
        this.userResponse = 'accepted';
        if (environment.production) {
            setInterval(() => {
            --this.redirectCountdown
        
            if (this.redirectCountdown === 0) {
                this.goToSamlLogin()
            }
            }, 1000)
        } else {
            this.localLogin = true;
        }
    }

    redirecting(): boolean {
        return this.userResponse == 'accepted';
    }

    deciding(): boolean {
        return this.userResponse == 'pending';
    }
    
    goToSamlLogin(): void {
        //if(environment.production) {
            window.location.replace(this.loginUrl)
        //} else {
            // no local login
        //}
    }

    testSite(): boolean {
        return !environment.production
    }
    
    get f() { return this.form.controls; }

    onSubmit(): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.accountService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.router.navigate(['/']);
                },
                error: error => {
                    // error
                    this.submitted = false;
                }
            });
    }
}

