<div class="container">
    <h1>Reports</h1>

    <div *ngIf="!loading; else isLoading">
        <div class="action-buttons">
            <button mat-flat-button color="primary" [routerLink]="['/admin/report_form']">New Report</button>
        </div>
        <table class="admin-table">
            <thead>
            <th (click)="sort('manufacturer_name')" style="width: 30%">Manufacturer</th>
            <th (click)="sort('product_name')" style="width: 30%">Product</th>
            <th (click)="sort('display_name')" style="width: 50%">Report</th>
            <th (click)="sort('is_verified')"style="width: 10%">Verified</th>
            <th (click)="sort('test_report_date')"style="width: 10%">Date</th>
            </thead>
            <tbody>
            <tr *ngFor="let report of reports" (click)="view(report)">
                <td>{{report.manufacturer_name}}</td>
                <td>{{report.product_name}}</td>
                <td>{{report.display_name}}</td>
                <td class="verified-td"><mat-icon *ngIf="report.is_verified" class="verified-badge">verified</mat-icon></td>
                <td>{{report.test_report_date}}</td>
            </tr>
            </tbody>
        </table>

    </div>

    <ng-template #isLoading>
        Loading...
    </ng-template>
</div>
