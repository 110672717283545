export class Utils {
  /** traverses json keys - nesting ok  **/
  static jsonTraverse(obj: any, fn: (obj: any, prop: any, scope?: any) => void) {
    for (const key in obj) {
      fn.apply(obj, [key, obj[key], obj]);
      if (obj[key] !== null && typeof (obj[key]) === 'object') {
        Utils.jsonTraverse(obj[key], fn);
      }
    }
  }
}
