import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {ApiService} from '../services/api.service'

@Component({
  selector: 'users-page',
  templateUrl: './users.html'
})

export class UsersComponent implements OnInit {

  users = []
  loading = true
  sortUp = true

  constructor(private router: Router,
              private api: ApiService){

  }
  ngOnInit() {
    this.api.get('users').subscribe(res => {
      this.users = res
      this.loading = false
    }, error => {
      if(error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        console.log(error)
        this.loading = false
      }
    })
  }

  sort(colName) {
    if (this.sortUp) {
      this.users.sort((a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0)
    } else {
      this.users.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
    }
    this.sortUp = !this.sortUp
  }

  view(user) {
     this.router.navigate([`/admin/user/${user.id}`])
  }
}
