import { Component, OnInit } from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {ApiService} from '../services/api.service'

@Component({
  templateUrl: './user.html'
})

export class UserComponent implements OnInit {

  selectedUser: any = null
  loading = true
  roles = ['user', 'analyst', 'admin']
  statuses = ['active', 'pending', 'disabled']
  saving = false
  errorMsgs = []

  constructor(private router: Router,
              private route: ActivatedRoute,
              private api: ApiService) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')

    if (id) {
      if (id !== '0') {
        this.api.get(`users/${id}`).subscribe(res => {
          this.selectedUser = res
          this.loading = false
        }, error => {
          this.loading = false
        })
      } else {
        // new user
        this.selectedUser = {status: 'pending', role: 'user'}
      }
    } else {
      this.router.navigate(['/admin/users'])
    }
  }

  saveUser(): void {
    this.saving = true
    if (this._isValid()) {
      if (this.selectedUser.id) {
        this.api.put(`users/${this.selectedUser.id}`, this.selectedUser).subscribe(res => {
          this.router.navigate(['/admin/users'])
          this.saving = false
        }, error => {
          if (error.status === 401) {
            this.router.navigate(['/login'])
          } else {
            this.saving = false
          }
        })
      } else {
        this.api.post('users/', this.selectedUser).subscribe(res => {
          this.router.navigate(['/admin/users'])
          this.saving = false
        }, error => {
          if (error.status === 401) {
            this.router.navigate(['/login'])
          } else {
            this.saving = false
          }
        })
      }
    }
  }

  _isValid(): boolean {
    let isV = true
    if(!this.selectedUser.name || this.selectedUser.name.length === 0) {
      this.errorMsgs.push("Name is required")
      isV = false
    }
    if(!this.selectedUser.email || this.selectedUser.email.length === 0) {
      this.errorMsgs.push("Email is required")
      isV = false
    }
    if(!this.selectedUser.status) {
      this.errorMsgs.push("Status is required")
      isV = false
    }
    if(!this.selectedUser.role) {
      this.errorMsgs.push("Role is required")
      isV = false
    }
    return isV
  }



}
