import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { first } from 'rxjs/operators';

import { AccountService } from './account.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const user = this.accountService.userValue;
        if (user) {
            return true;
        }
        let token = route.queryParams["apiToken"];

        if(token) {
            this.accountService.loginWithToken(token).pipe(first())
                .subscribe({
                    next: () => {
                        this.router.navigate(['/'], {});
                        return true;
                    },
                    error: error => {
                        if(environment.production) {
                            window.location.href = `${environment.baseUrl}saml/login`;
                        } else {
                            this.router.navigate(['/login']);
                        }
                        return false;
                    }
                });
        } else {
            if(environment.production) {
                window.location.href = `${environment.baseUrl}saml/login`;
            } else {
                this.router.navigate(['/login']);
            }
            return false;
        }
    }
}
