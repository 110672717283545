<div class="no-margin">
    <mat-toolbar color="cuas-primary">
        <div style="width: 100%;">
            <div class="title">
                <a mat-button [routerLink]="['/search']" >
                    Counter-UAS Selection Tool
                </a>
            </div>
            <div class="version">
                <sub>V 0.5.00-00 </sub>
                <sub *ngIf="lastUpdate">&nbsp;&nbsp;({{lastUpdate | date:'MM/dd/yy'}})</sub>
            </div>
            <div class="adminButton"  *ngIf="(user$ | async) as user">
                <button mat-button (click)="goToAdmin()" *ngIf="user.admin">
                    Admin
                </button>

                <button mat-button (click)="logout()">
                    Logout <mat-icon>logout</mat-icon>
                </button>

            </div>
        </div>
    </mat-toolbar>

    <div class="disclaimer">
        <h3>This application contains dynamic content -- Highest Permissible Classification is CUI</h3>
    </div>
</div>
