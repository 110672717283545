import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {ApiService} from '../services/api.service'
import {formatCurrency} from "@angular/common";

@Component({
  selector: 'reports-page',
  templateUrl: './reports.html'
})

export class ReportsComponent implements OnInit {

  reports = []
  loading = true
  sortUp = true
  selectedReport = null

  constructor(private router: Router,
              private api: ApiService){

  }

  ngOnInit() {
    this.api.get('report_sources').subscribe(res => {
      this.reports = res
      this.loading = false
    }, error => {
      if(error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        console.log(error)
        this.loading = false
      }
    })
  }

  sort(colName) {
    if (this.sortUp) {
      this.reports.sort((a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0)
    } else {
      this.reports.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
    }
    this.sortUp = !this.sortUp
  }

  view(report) {
    this.router.navigate([`/admin/report/${report.id}`])
  }

}
