<div class="container admin-report" >

    <div *ngif="loading; else loaded">
        Loading ...
    </div>
    <div #loaded>
        <h1 *ngIf="isNew; else reportName">New Report</h1>
        <ng-template #reportName><h1>{{report.display_name}}</h1></ng-template>

            <div class="properties-panel">
                <div *ngIf="isNew; else prodName" class="">
                    <div *ngIf="!showProductForm">
                        <mat-form-field class="admin-prod-detail-field">
                            <mat-label>Select Product</mat-label>
                            <mat-select class="admin-input" [(ngModel)]="report.product_id">
                                <mat-option *ngFor="let product of products" [value]="product.id">{{ product.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-button color="primary" (click)="setProdForm(true)">Or Add New Product</button>
                    </div>

                    <div *ngIf="showProductForm">
                        <h2 class="section-title">
                            New Product Details
                            <button mat-stroked-button color="primary" class="cancel-right" (click)="setProdForm(false)">Cancel</button>
                        </h2>
                        <mat-form-field class="admin-prod-detail-field">
                            <mat-label>Product Name</mat-label>
                            <input matInput type="text" [(ngModel)]="report.product.name"/>
                        </mat-form-field>
                        <mat-form-field class="admin-prod-detail-field">
                            <mat-label>Model</mat-label>
                            <input matInput type="text"  [(ngModel)]="report.product.model"/>
                        </mat-form-field>
                        <mat-form-field class="admin-prod-desc-field">
                            <mat-label>Description</mat-label>
                            <input matInput type="text"  [(ngModel)]="report.product.description"/>
                        </mat-form-field>


                        <div *ngIf="!showMfrForm">
                            <mat-form-field class="admin-prod-detail-field">
                                <mat-label>Select Manufacturer</mat-label>
                                <mat-select class="admin-input" [(ngModel)]="report.product.manufacturer_id">
                                    <mat-option *ngFor="let mfr of manufacturers" [value]="mfr.id">{{ mfr.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-button color="primary" (click)="setMfrForm(true)">Or Add New Manufacturer</button>
                        </div>
                        <div *ngIf="showMfrForm">
                            <button mat-stroked-button color="primary" class="cancel-right" (click)="setMfrForm(false)">Cancel</button>

                            <mat-form-field class="admin-prod-detail-field">
                                <mat-label>Manufacturer</mat-label>
                                <input matInput type="text" [(ngModel)]="report.product.manufacturer.name"/>
                            </mat-form-field>
                            <mat-form-field class="admin-prod-detail-field">
                                <mat-label>Website</mat-label>
                                <input matInput type="text" [(ngModel)]="report.product.manufacturer.website"/>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <ng-template #prodName>
                    <div><h2>{{report.product.name}}</h2></div>
                </ng-template>

                <h2 class="section-title">Test Event Details</h2>
                <div class="admin-prod-detail-field">
                    <mat-checkbox name="report.is_verified" [(ngModel)]="report.is_verified" (change)="toggleVerifieds()">Is Verified?</mat-checkbox>
                </div>
                <mat-form-field class="admin-prod-detail-field">
                    <mat-label>Title</mat-label>
                    <input matInput type="text" name="report.test_report_title" [(ngModel)]="report.test_report_title"/>
                </mat-form-field>
                <mat-form-field class="admin-prod-detail-field">
                    <mat-label>Organization</mat-label>
                    <input matInput type="text" name="report.test_org" [(ngModel)]="report.test_org"/>
                </mat-form-field>
                <mat-form-field class="admin-prod-detail-field">
                    <mat-label>Location</mat-label>
                    <input matInput type="text" name="report.test_report_location" [(ngModel)]="report.test_report_location"/>
                </mat-form-field>
                <mat-form-field class="admin-prod-detail-field">
                    <mat-label>Country</mat-label>
                    <mat-select class="admin-input" [(ngModel)]="report.country" name="report.country">
                        <mat-option *ngFor="let country of countries" [value]="country">{{ country }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="admin-prod-detail-field">
                    <mat-label>Event</mat-label>
                    <input matInput type="text" name="report.test_event" [(ngModel)]="report.test_event"/>
                </mat-form-field>
                <mat-form-field class="admin-prod-detail-field">
                    <mat-label>Date</mat-label>
                    <input matInput type="date" required name="report.test_report_date" [(ngModel)]="report.test_report_date" />
                </mat-form-field>

                <div *ngFor="let section of sections" class="properties-section">
                    <h2 class="section-title">{{section.name}}</h2>
                    <div  class="property-picker" *ngFor="let property of section.properties">

                        <h3>{{ property.name }} <mat-icon [matTooltip]="property.help" matTooltipPosition="right" *ngIf="property.help">help_outline</mat-icon>
                            <span *ngIf="property.selected_option">verified <mat-checkbox [(ngModel)]="property.is_verified"></mat-checkbox></span>
                        </h3>
                        <!-- Select -->
                        <div *ngIf="property.kind == 'slider' && property.name !== 'Cost'">
                            <mat-form-field class="admin-input">
                                <input matInput type="text" [(ngModel)]="textOpts[property.id]"/>
                            </mat-form-field>
                        </div>
                        <div *ngIf="property.kind != 'slider' && property.name != 'Cost'">
                            <!--div *ngIf="property.kind == 'multiple'; else singleSelect">
                                <div *ngFor="let option of property.options">
                                    <mat-checkbox [checked]="report.opts.includes(option.id)"
                                                  (change)="toggleOpt(option.id)"
                                                  [value]="option.id">{{ option.value }}</mat-checkbox>
                                </div>
                            </div-->
                            <!--ng-template #singleSelect-->
                                <mat-select class="admin-input" [value]="property.selected_option" (selectionChange)="toggleOptEvt($event, property)">
                                    <mat-option *ngFor="let option of property.options" [value]="option.id">{{ option.value }}</mat-option>
                                </mat-select>
                            <!--/ng-template-->
                        </div>
                    </div>
                </div>
            </div>

            <div class="action-buttons">
                <button mat-flat-button color="primary" (click)="saveProperties()" [disabled]="saving">Save Changes</button>
            </div>

    </div>
</div>
