import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, from } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(private http: HttpClient) {}

  // Get
  get(path): Observable<any> { return this.call('GET', path) }

  // Post
  post(path, params): Observable<any> { return this.call('POST', path, {}, params) }

  // Put
  put(path, params): Observable<any> { return this.call('PUT', path, {}, params) }

  login(params): Observable<any> { return this.http.post(environment.baseUrl+'auth/login', params) }

  // Call
  call(verb: string, path: string, params?: any, body?: any): Observable<any> {
    return from(this.asyncCall(verb, path, params, body))
  }

  async asyncCall(verb: string, path: string, params?: any, body?: any) {
    const reqOpts = {headers:  this.getHeaders(), params: {} }

    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        reqOpts.params[key] = params[key]
      }
    }

    switch (verb.toUpperCase()) {
      case 'POST':    return await this.http.post(environment.apiUrl + path, body, reqOpts).toPromise()
      case 'PUT':    return await this.http.put(environment.apiUrl + path, body, reqOpts).toPromise()
      case 'DELETE':  return await this.http.delete(environment.apiUrl + path, reqOpts).toPromise()
      default:        return await this.http.get(environment.apiUrl + path, reqOpts).toPromise()
    }
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'responseType': 'json' })
}

}
