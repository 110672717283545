<mat-sidenav-container class="sidenav-container">
    <mat-sidenav class="sidenav-side" mode="side" opened>
        <div class="">
            <span class="results-found">Admin</span>
        </div>
        <mat-action-list>
            <mat-list-item [ngClass]="{'active-section' : currentPage  == 'Reports'}" (click)="setPage('Reports', '/admin/reports')">
                <span>Reports</span>
            </mat-list-item>
            <mat-list-item [ngClass]="{'active-section' : currentPage  == 'Users'}"  (click)="setPage('Users', '/admin/users')">
                <span>Users</span>
            </mat-list-item>
        </mat-action-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
