import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { map } from 'rxjs/operators';
import { User } from '../models';
import { ApiService } from './api.service'

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    private lastUpdateBS: BehaviorSubject<string>;
    public lastUpdate: Observable<string>;

    constructor(
        private router: Router,
        private api: ApiService
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
        this.lastUpdateBS = new BehaviorSubject<string>(null);
        this.lastUpdate = this.lastUpdateBS.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }
    public setLastUpdate(val: string) {
        this.lastUpdateBS.next(val);
    }

    /** may not ever use, but keeping for testing */
    login(username: string, password: string) {
        let opts = { email: username, password: password };
        return this.api.login(opts)
            .pipe(map((user: User) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                console.log("subscribed")
                return user;
            }));
    }

    loginWithToken(apiToken: string) {
        let opts = {api_token: apiToken};
        return this.api.login(opts)
            .pipe(map((user: User) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
            return user;
        }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/']);
    }

}
