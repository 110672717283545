<div class="login-background">
    <div class="seal-container">
        <img src="assets/Seal.png" class="seal-image">
    </div>
    <div class="content-container results">
        <div class="content">
            <p>
                The information provided by the U.S. Department of Energy's Office of Security Assistance (EHSS-52) in 
                the Counter Unmanned Aerial Systems (C-UAS) Selection Tool is for general informational purposes only.
                  Reference in this database to any specific commercial product, process, service, manufacturer, 
                company, or trademark is to provide clarity and does not constitute its endorsement or recommendation 
                by EHSS-52.  All information in the database is provided in good faith; however, this tool does not 
                substitute for the user's own independent verification that a system identified by the tool meets the 
                program's/site's requirements.
            </p>
            <p>
                This tool is designed to present a list of Counter-UAS solutions based on selection criterion
                applicable to a site. Users will make selections based on the site where a Counter-UAS solution is
                needed, and the tool will present a list of systems that best meet that site's needs.
            </p>
            <p>
                For any questions, comments, or concerns please contact DOE EHSS-52 at
                <a href="mail:bill.benton@hq.doe.gov">e-mail@doe.hq.gov</a>
            </p>

            <div class="actions centered">
                <button mat-flat-button class="cuas-primary-button" color="" (click)="beginSearch()">Start Here</button>
            </div>
        </div>

    </div>


</div>
