import { Component } from "@angular/core";
import { Router } from '@angular/router';

@Component({ templateUrl: './home.html' })

export class HomeComponent {

    constructor(private router: Router){

    }

    beginSearch() {
        this.router.navigate(['search']);
    }
}
